import React from "react";
import "./App.css";
import SocialFollow from "./components/Socials/Footer";
import Navbar from "./components/Navigation/Navbar";
import Recipes from "./components/Recipes/Recipes";
import { Routes, Route } from "react-router-dom";

import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#000000",
    },
    secondary: {
      main: "#ff4f4f",
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <Navbar />
        <Routes>
          <Route path="/" element={<Recipes />} />
        </Routes>
        <SocialFollow />
      </div>
    </ThemeProvider>
  );
}

export default App;
