import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";

export default function Navbar() {
  return (
    <AppBar position="sticky" style={{ background: "primary" }}>
      <Toolbar>
        <Typography variant="h6">Boricua Cooking 🇵🇷</Typography>
      </Toolbar>
    </AppBar>
  );
}
