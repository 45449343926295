import * as React from "react";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";

const useStyles = makeStyles({
  ingredients: {
    display: "block",
    textAlign: "left",
  },
  image: {
    marginTop: "30",
  },
});

type Recipe = {
  title: string;
  ingredients: string[];
  video_url: string;
  thumbnail: string;
};

var myRecipes: Recipe[] = [
  {
    title: "Pernil",
    ingredients: ["pork shoulder", "garlic", "sazon", "adobo", "parsley", "vinegar", "olive oil", "sofrito", "black pepper"],
    video_url: "https://www.youtube.com/watch?v=uxz1h7434A4",
    thumbnail: "/pernil.jpg",
  },
  {
    title: "Alcapurrias",
    ingredients: ["green plantain", "malanga", "pumpkin squash", "annato oil", "adobo", "garlic powder", "onion powder", "sazon"],
    video_url: "https://www.youtube.com/watch?v=9ruMEhYvZYs",
    thumbnail: "/alcapurrias.jpg",
  },
  {
    title: "Asopao de Camarones",
    ingredients: ["shrimp", "onions", "red pepper", "pimiento", "cilantro", "garlic", "cilantro", "bay leaves", "salt", "black pepper"],
    video_url: "https://www.youtube.com/watch?v=OvpXJEB7Cvo",
    thumbnail: "/asopadecamarones.jpg",
  },
  {
    title: "Pasteles",
    ingredients: ["yuca", "squash", "malanga", "annatto", "adobo", "onion powder", "garlic powder", "sazon"],
    video_url: "https://www.youtube.com/watch?v=C_MRhvnrcSc",
    thumbnail: "/pasteles.jpg",
  },
  {
    title: "Ropa Vieja",
    ingredients: ["churrasco", "green pepper", "onion", "sofrito", "olive oil", "tomato sauce", "potatoes", "garlic paste", "white wine", "beef broth", "cumin", "oregano", "sazon", "roasted red peppers"],
    video_url: "https://www.youtube.com/watch?v=K2wQ93cQDnI",
    thumbnail: "/ropavieja.jpg",
  },
  {
    title: "Piononos",
    ingredients: ["ground beef", "sofrito", "garlic paste", "tomato paste ", "sazón", "potatoes", "onions", "peppers", "salt", "black pepper"],
    video_url: "https://www.youtube.com/watch?v=XjnJx3CEHm8",
    thumbnail: "/piononos.jpg",
  },
];

export default function Recipes() {
  const classes = useStyles();

  return (
    <div>
      <Typography variant="h3">
        <Box sx={{ fontFamily: "default", m: 1 }}>Recipes</Box>
      </Typography>
      <Grid container direction="row" justifyContent="center" alignItems="center" spacing={{ xs: 1, sm: 2, md: 1 }} columns={{ xs: 4, sm: 8, md: 12 }} style={{ marginBottom: "50px" }}>
        {myRecipes.map((r, index) => {
          return (
            <Grid item key={index} xs={4} m={0}>
              <Card>
                <Typography gutterBottom variant="h5" component="div">
                  {r.title}
                </Typography>
                <CardMedia className={classes.image} component="img" height="200" image={r.thumbnail} />
                <CardContent>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                      <Typography>Ingredients</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className={classes.ingredients}>
                        <ul>
                          {r.ingredients.map((i, index) => {
                            return <li key={index}>{i}</li>;
                          })}
                        </ul>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </CardContent>
                <Button style={{ marginBottom: "20px" }} variant="contained" color="secondary" href={r.video_url} target="_blank">
                  watch me make this
                </Button>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
}
