import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faYoutube, faInstagram, faTiktok, faFacebook } from "@fortawesome/free-brands-svg-icons";

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  footer: {
    fontFamily: "sans-serif",
    marginTop: "1rem",
    position: "fixed",
    right: "0",
    bottom: "0",
    left: "0",
    width: "100%",
    color: "#fafafa",
    backgroundColor: "black",
  },
  socials: {
    color: "#fafafa",
    margin: "0.5rem",
  },
});

function SocialFollow() {
  const classes = useStyles();

  return (
    <div className={classes.footer}>
      <h3>Socials</h3>
      <a href="https://www.youtube.com/c/cookingconlabori" className={classes.socials} target="_blank">
        <FontAwesomeIcon icon={faYoutube} size="2x" />
      </a>
      <a href="https://www.instagram.com/cookingconlabori" className={classes.socials} target="_blank">
        <FontAwesomeIcon icon={faInstagram} size="2x" />
      </a>
      <a href="https://www.facebook.com/groups/cookingwithlborivazquez" className={classes.socials} target="_blank">
        <FontAwesomeIcon icon={faFacebook} size="2x" />
      </a>
      <a href="https://www.tiktok.com/@cookingconlabori" className={classes.socials} target="_blank">
        <FontAwesomeIcon icon={faTiktok} size="2x" />
      </a>
    </div>
  );
}

export default SocialFollow;
